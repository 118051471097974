import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga';
import Page from '@components/page';
import VideoPlayer from '@components/video-player';
import IntroModal from '@components/intro-modal';
import { Colors, Fonts } from '@styles';
import Assets from '@assets';
import DATA from '@data';

const StoriesContainer = styled.div`
  width: 100%;
  min-height: 90%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0 0 0 60px;
`;

const StoriesList = styled.div`
  width: 30%;
  height: 70vh;
  margin-left: 2%;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.scrollbarTrack};
    border-radius: 10px;
    margin: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 65vh;
  }
`;

const StoriesContent = styled.div`
  width: 70%;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  display: flex;
  background: ${({ active }) => (!active ? Colors.white : null)};
  box-shadow: ${({ active }) => (!active ? '0 0 14px 0px rgb(0 0 0 / 20%)' : null)};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FlippedImage = styled.img`transform: rotateY(180deg);`;

const StoriesNoContentText = styled.div`
  margin-top: 40px;
  font-size: 2.1rem;
  color: ${Colors.darkGrey};
  text-align: center;
`;

const StoryItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all linear 0.2s;
  position: relative;
  height: 85px;
  padding: 0 20px;
`;

const StoryItemWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: all linear 0.2s;
  background: ${({ active }) => (active ? Colors.alphaPurple : null)};

  &:hover {
    background: ${Colors.alphaPurple};
  }
`;

const StoryItemHeader = styled.div`
  margin-left: 23px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const StoryItemNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
`;

const StoryItemName = styled.div`
  font-family: ${Fonts.regular};
  color: ${Colors.primary};
  font-size: 1rem;
  position: relative;
`;

const StoryItemTopic = styled.div`
  font-family: ${Fonts.bold};
  color: ${Colors.primary};
  font-size: 1rem;
  margin-top: ${({ active }) => (active ? '13px' : '7px')};

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 0.9rem;
  }
`;

const StoryItemImage = styled.img`
  width: 52px;
  height: 52px;
`;

const StoryItemDuration = styled.div`
  font-family: ${Fonts.bold};
  color: ${Colors.primary};
  font-size: 1rem;
  margin-left: 23px;
  position: absolute;
  right: 30px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 0.9rem;
    right: 10px;
  }
`;

const StoryItemDescription = styled.div`
  height: ${({ show }) => (show ? 'auto' : 0)};
  color: ${Colors.primary};
  font-weight: 100;
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0 20px 20px 95px;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const StoryItemAudio = styled.div`
  position: absolute;
  top: -14px;
  right: -50px;
  z-index: 100;

  > img {
    width: 48px;
  }
`;

const StoryItem = ({ data, active = false, onClick, playingVideo }) => {
  const audio = useRef(null);
  const [ playingAudio, setPlayingAudio ] = useState(false);

  useEffect(() => {
    if (data.voiceover) {
      audio.current = new Audio(data.voiceover);
      audio.current.addEventListener('ended', handleAudioOnEnded);

      return () => {
        audio.current.pause();
        audio.current.removeEventListener('ended', handleAudioOnEnded);
        audio.current = null;
      };
    }
  }, []);

  useEffect(
    () => {
      if (playingVideo && active) {
        if (audio.current) {
          setPlayingAudio(false);
          audio.current.pause();
        }
      }
    },
    [ playingVideo ]
  );

  useEffect(
    () => {
      if (!active) {
        audio.current.pause();
      }
    },
    [ active ]
  );

  const toggleAudio = e => {
    e.preventDefault();
    e.stopPropagation();

    const newVal = !playingAudio;
    setPlayingAudio(newVal);

    if (newVal) {
      audio.current.play();
    } else {
      audio.current.pause();
    }
  };

  const handleAudioOnEnded = () => {
    setPlayingAudio(false);
  };

  const handleOnClick = () => {
    onClick(data.id);
  };

  return (
    <StoryItemWrapper active={active} onClick={handleOnClick}>
      <StoryItemContainer>
        <StoryItemImage src={data.image} />
        <StoryItemHeader>
          <StoryItemNameWrapper>
            <StoryItemName>
              {data.name}
              {active && audio.current ? (
                <StoryItemAudio onClick={toggleAudio}>
                  <img src={playingAudio ? Assets.images.AudioMutedIcon : Assets.images.AudioIcon} />
                </StoryItemAudio>
              ) : null}
            </StoryItemName>
            <StoryItemTopic active={active}>{data.topic}</StoryItemTopic>
          </StoryItemNameWrapper>
        </StoryItemHeader>
        <StoryItemDuration>{data.file_duration}</StoryItemDuration>
      </StoryItemContainer>
      <StoryItemDescription show={active}>{data.description}</StoryItemDescription>
    </StoryItemWrapper>
  );
};

function iOS() {
  if (typeof navigator !== 'undefined') {
    return (
      [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
}

const Soar = () => {
  const pageTitle = 'Soar';
  const pageSubTitle = "Patients' experience with TGP";

  const [ showIntroModal, setShowIntroModal ] = useState(false);
  const [ completedSteps, setCompletedSteps ] = useState({});
  const [ activeStoryItem, setActiveStoryItem ] = useState(null);
  const [ rightNavigationActive, setRightNavigationActive ] = useState(false);
  const [ playingVideo, setPlayingVideo ] = useState(false);
  const [ videoContainerHeight, setVideoContainerHeight ] = useState(null);

  useEffect(() => {
    if (localStorage.getItem('session-id')) {
      const soarIntro = window.localStorage.getItem('soar-intro');
      if (!soarIntro || soarIntro != 1) {
        setShowIntroModal(true);
      }

      let steps = window.localStorage.getItem('completed-steps');
      if (steps) {
        steps = JSON.parse(steps);
        setCompletedSteps(steps);

        if (steps.soar === true) {
          setRightNavigationActive(true);
          setShowIntroModal(false);
        }
      }
    } else {
      navigate('/intro');
    }
  }, []);

  const handleOnStoryItemClick = storyItemId => {
    setActiveStoryItem(storyItemId);
    setPlayingVideo(false);
  };

  const handleOnVideoPlay = () => {
    setPlayingVideo(true);
  };

  const handleOnVideoPause = () => {
    setPlayingVideo(false);
  };

  const handleOnVideoFinish = () => {
    if (!rightNavigationActive) {
      setRightNavigationActive(true);
    }

    const item = DATA.SOAR.find(s => s.id === activeStoryItem);
    ReactGA.event({
      category: 'SOAR',
      action: `User viewed "${item.name}'s" video`,
    });
  };

  const handleOnLeftNavigationPress = () => {
    if (completedSteps['glide']) {
      navigate('/glide');
    }
  };

  const handleOnRightNavigationPress = () => {
    let completedSteps = window.localStorage.getItem('completed-steps');
    if (completedSteps) {
      completedSteps = JSON.parse(completedSteps);
      completedSteps.soar = true;
    }
    window.localStorage.setItem('completed-steps', JSON.stringify(completedSteps));

    navigate('/navigate');
  };

  const handleIntroModalOnClose = () => {
    setShowIntroModal(false);

    window.localStorage.setItem('soar-intro', 1);
  };

  const handleOnHeightChanged = height => setVideoContainerHeight(height);

  return (
    <React.Fragment>
      <Page
        layout={iOS() ? '12% 1% 66% 11% 2%' : null}
        title={pageTitle}
        subTitle={pageSubTitle}
        icon={Assets.images.PageIconSoar}
        audio={Assets.audios.PageAudioSoar}
        withNavigation
        fullContent
        navigationSize={iOS() ? 'sm' : 'md'}
        leftNavigationActive={true}
        leftNavigationText="Glide"
        onLeftNavigationPress={handleOnLeftNavigationPress}
        rightNavigationActive={rightNavigationActive}
        rightNavigationText="Navigate"
        onRightNavigationPress={handleOnRightNavigationPress}
      >
        <StoriesContainer>
          <StoriesContent height={iOS() ? null : videoContainerHeight} active={activeStoryItem}>
            {!activeStoryItem ? (
              <React.Fragment>
                <FlippedImage src={Assets.images.PointerIcon} />
                <StoriesNoContentText>
                  Click on a person’s name to the right to<br />hear their real life stories
                </StoriesNoContentText>
              </React.Fragment>
            ) : (
              <VideoPlayer
                file={DATA.SOAR.find(s => s.id === activeStoryItem).file}
                transcript={DATA.SOAR.find(s => s.id === activeStoryItem).file_transcript}
                onPlay={handleOnVideoPlay}
                onPause={handleOnVideoPause}
                onFinish={handleOnVideoFinish}
                autoplay={!iOS()}
                onHeightChanged={handleOnHeightChanged}
              />
            )}
          </StoriesContent>
          <StoriesList>
            {DATA.SOAR.map(story => (
              <StoryItem
                key={story.id}
                data={story}
                active={activeStoryItem === story.id}
                onClick={handleOnStoryItemClick}
                playingVideo={playingVideo}
              />
            ))}
          </StoriesList>
        </StoriesContainer>
      </Page>
      {showIntroModal ? (
        <IntroModal
          icon={Assets.images.PageIntroIconSoar}
          audio={Assets.audios.PageAudioSoar}
          onClose={handleIntroModalOnClose}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Soar;
